<template>
    <router-view />
</template>

<style lang="scss">
    body {
        font-family: 'Varta', sans-serif;
        margin: 0;
        padding: 0;
        cursor: pointer;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        a, button {
            font-family: inherit;
            line-height: 1em;
            box-sizing: border-box;
            text-align: center;
            text-decoration: none;
            border: none;
            -webkit-font-smoothing: antialiased;

            &.yellow {
                font-family: "Assistant", "Avenir", "Arial", sans-serif;
                color: #262223;
                background-color: #ffe82f;
            }
        }
    }
</style>
