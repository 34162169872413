import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import RoomView from '@/views/RoomView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'room',
    component: RoomView,
  },
  {
    path: '/render/:url',
    name: 'rendered',
    props: true,
    component: () => import(/* webpackChunkName: "rendered" */ '@/views/RenderView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
