import RoomItemProps from "@/types/RoomItemProps"

const categories: { name: string; items: RoomItemProps[]; key: string }[] = [
  {
    key: "seating",
    name: "Seating",
    items: [
      {
        image: "bench.png",
        slug: "bench",
        name: "Bench",
      },
      {
        image: "wooden-chair.png",
        slug: 'wooden-chair',
        name: "Wooden Chair",
      },
    ],
  },
  {
    key: "wall-art",
    name: "Wall Art",
    items: [
      {
        image: "apple-painting.png",
        slug: "apple-painting",
        name: "Apple Painting",
      },
      {
        image: "mountain-painting.png",
        slug: "mountain-painting",
        name: "Mountain Painting",
      },
      {
        image: "poster.png",
        slug: "poster",
        name: "Poster",
      },
      {
        image: "sand-photo.png",
        slug: "sand-photo",
        name: "Sand Photograph",
      },
      {
        image: "windmill-painting.png",
        slug: "windmill-painting",
        name: "Windmill Painting",
      },
    ],
  },
  {
    key: "accent-pieces",
    name: "Accent Pieces",
    items: [
      {
        image: "easel.png",
        name: "Easel",
        slug: "easel",
      },
      {
        image: "shelf.png",
        name: "Shelf",
        slug: "shelf",
      },
      {
        image: "mirror.png",
        name: "Mirror",
        slug: "mirror",
      },
    ],
  },
  {
    key: "tables",
    name: "Tables",
    items: [
      {
        image: "sidetable.png",
        name: "Sidetable",
        slug: "sidetable",
      },
      {
        image: "table.png",
        name: "Table",
        slug: "table",
      },
      {
        image: "desk.png",
        name: "Desk",
        slug: "desk",
      },
      {
        image: "dresser.png",
        name: "Dresser",
        slug: "dresser",
      },
      {
        image: "display-case.png",
        name: "Display Case",
        slug: "display-case",
      },
    ],
  },
  {
    key: "small-items",
    name: "Small Items",
    items: [
      {
        image: "brushes.png",
        name: "Brushes",
        slug: "brushes",
      },
      {
        image: "cat-in-blanket.png",
        name: "Cat in a Blanket",
        slug: "cat-in-blanket",
      },
      {
        image: "flower.png",
        name: "Flower",
        slug: "flower",
      },
      {
        image: "potted-plant.png",
        name: "Potted Plant",
        slug: "potted-plant",
      },
      {
        image: "book.png",
        name: "Book",
        slug: "book",
      },
      {
        image: "tea-cup.png",
        name: "Tea Cup",
        slug: "tea-cup",
      },
      {
        image: "tea-pot.png",
        name: "Tea Pot",
        slug: "tea-pot",
      },
    ],
  },
]
export default categories
